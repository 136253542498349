import * as React from "react"
import Header from "../components/Header";
import Layout from "../components/layout/Layout";
import {container, loader} from "../assets/pageStyles/pricing.module.scss";
import {appConfig} from "../assets/config";
import {graphql, navigate} from 'gatsby';
import {useEffect} from "react";
import Loader from "../components/loader/Loader";
import {useI18next} from "gatsby-plugin-react-i18next";

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const PricingPage = (props) => {
    const {language} = useI18next();


    useEffect(() => {
        const signupRoute = appConfig.returnIframeRouteBillingPackages(language)
        navigate(signupRoute)
    }, [language])

    return (
        <Layout location={props?.location?.pathname}>
            <div className={container}>
                <div className={loader}><Loader/></div>
            </div>
        </Layout>
    )
}

export default PricingPage

export const Head = () => <Header title={"away | Pricing"}/>

